import React from 'react';
// Action types
export const CONNECT_WEBSOCKET = 'CONNECT_WEBSOCKET';
export const RECONNECT_COUNTER = 'RECONNECT_COUNTER';
export const CHANGE_WS_STATUS = 'CHANGE_WS_STATUS';
export const CHAT_MESSAGE = 'CHAT_MESSAGE';
export const CHAT_TRANSCRIPT = 'CHAT_TRANSCRIPT';
export const SEND_MESSAGE_FROM_QUEUE = 'SEND_MESSAGE_FROM_QUEUE';
export const INIT_CONVERSATION = 'INIT_CONVERSATION';
export const REMOVE_STARTING_QUESTION = 'REMOVE_STARTING_QUESTION';
export const REMOVE_COOKIES = 'REMOVE_COOKIES';
export const BOT_SESSION_ID = 'BOT_SESSION_ID';
export const BOT_ANIMATION = 'BOT_ANIMATION';
export const AGENT_TYPING = 'AGENT_TYPING';
export const AGENT_NOT_TYPING = 'AGENT_NOT_TYPING';
export const ADD_MESSAGE = 'ADD_MESSAGE';
export const AGENT_CONNECTED = 'AGENT_CONNECTED';
export const AGENT_LEFT = 'AGENT_LEFT';
export const QUEUE_UPDATED = 'QUEUE_UPDATED';
export const LIVE_AGENT_SESSION_ID = 'LIVE_AGENT_SESSION_ID';
export const CHAT_ENDED = 'CHAT_ENDED';
export const CHAT_IDLE_ENDED = 'CHAT_IDLE_ENDED';
export const HANDOVER_START = 'HANDOVER_START';
export const HANDOVER_ACCEPTED = 'HANDOVER_ACCEPTED';
export const HANDOVER_FAILED = 'HANDOVER_FAILED';
export const ERROR = 'ERROR';
export const REMOVE_NOTIFICATION_IDLE = 'REMOVE_NOTIFICATION_IDLE';
export const NOTIFICATION = 'NOTIFICATION';
export const ADD_NOTIFICATION = 'ADD_NOTIFICATION';
export const REMOVE_NOTIFICATION = 'REMOVE_NOTIFICATION';
export const ADD_MESSAGE_TO_QUEUE = 'ADD_MESSAGE_TO_QUEUE';
export const REMOVE_MESSAGE_FROM_QUEUE = 'REMOVE_MESSAGE_FROM_QUEUE';
export const UPDATE_MESSAGE_ACK = 'UPDATE_MESSAGE_ACK';
export const ACK = 'ACK';
export const WS_MESSAGE_RECEIVED = 'WS_MESSAGE_RECEIVED';
export const SEND_THROUGH_SOCKET = 'SEND_THROUGH_SOCKET';
export const SET_CHAT_ENDED_SIGNAL = 'SET_CHAT_ENDED_SIGNAL';
export const SET_CHAT_ENDED_REASON = 'SET_CHAT_ENDED_REASON';
export const SET_CHAT_TRANSITION_SIGNAL = 'SET_CHAT_TRANSITION_SIGNAL';
export const SET_NEW_MESSAGES_COUNT = 'SET_NEW_MESSAGES_COUNT';
export const SECOND_CONNECTION = 'SECOND_CONNECTION';
export const SET_POPIN_OPEN = 'SET_POPIN_OPEN';
export const TAG_CONVERSATION = 'TAG_CONVERSATION';
export const TOGGLE_MENU_MODAL = 'TOGGLE_MENU_MODAL';
export const CLOSE_WS_CONNECTION = 'CLOSE_WS_CONNECTION';
export const DISABLE_RECONNECT = 'DISABLE_RECONNECT';
export const RESET_STATE = 'RESET_STATE';
export const URL_POST_CHAT = 'URL_POST_CHAT';
export const CSAT_HANDOVER = 'CSAT_HANDOVER';
export const CHANGE_MARKET = 'CHANGE_MARKET';
export const CHANGE_INTENT_ID = 'CHANGE_INTENT_ID';
export const GET_TRANSCRIPT = 'GET_TRANSCRIPT';
export const LOGIN_STATE = 'LOGIN_STATE';
export const LOGIN_FROM_START_COMPLETED = 'LOGIN_FROM_START_COMPLETED';
export const CML_DA_REQUEST = 'CML_DA_REQUEST';

// Other constants
export const ACTOR_USER = 'ACTOR_USER';
export const ACTOR_AGENT = 'ACTOR_AGENT';
export const BOT = 'BOT';
export const HUMAN = 'HUMAN';
export const IN_QUEUE = 'IN_QUEUE';
export const HANDOVER = 'HANDOVER';
export const SELECT_TIMESLOT = 'SELECT_TIMESLOT';
export const ENDED_BY_AGENT = 'heeft de chat afgesloten';
export const ENDED_BY_IDLE_TIMEOUT = 'heeft de chat verlaten';
export const LOCALSTORAGE_MESSAGE_IDS = 'STORAGE_RECEIVED_MESSAGE_IDS';
export const LOCALSTORAGE_QUEUE = 'STORAGE_MESSAGE_QUEUE';
export const LOCALSTORAGE_EXPIRES_IN = 'STORAGE_EXPIRES_IN';
export const SET_STORAGE_EXPIRY_TIME = 'SET_STORAGE_EXPIRY_TIME';
export const CHECK_STORAGE_EXPIRY_TIME = 'CHECK_STORAGE_EXPIRY_TIME';
export const CLEAR_STORAGE = 'CLEAR_STORAGE';
export const SHADOW_ROOT_ID = 'chat-ui-shadow-root';
// value in seconds, which means STORAGE_EXPIRY_TIME is set below to 120 seconds
export const STORAGE_EXPIRY_TIME = 120;
export const COOKIE_EXPIRY_TIME = 5400;  // 90m -> 90*60
export const HANDOVER_STATE = {
    IN_PROGRESS: 0,
    SUCCESS: 1,
    FAILURE: 2
};
export const AGENT_DISCONNECTED = 'ended by agent';
export const IDLE_DISCONNECTED = 'client idle timeout';

// Message constants
export const MARKET = { CONSUMER: 'CM', CONSUMER_PLATFORM: 'mix', BUSINESS: 'BM', BUSINESS_PLATFORM: 'mix' };
export const MESSAGES = {
    CM: {
        AGENT_CONNECTING: 'Je wordt verbonden met een medewerker.',
        AGENT_CONNECTED: 'Gelukt!',
        AGENT_CONNECTED_NAME: 'Je chat nu met ',
        HANDOVER_FAILED: 'Doorverbinden is mislukt. Je gesprek met de huidige medewerker wordt voortgezet.',
        DATA_PRIVACY_DISCLAIMER: 'Deze chat wordt bewaard ter verbetering van onze service.',
        DATA_PRIVACY_DISCLAIMER_NEW: <>Deze chat wordt bewaard ter verbetering van onze service.<br /><a href={process.env.REACT_APP_DISCLAIMER_PAGE_URL}>Dot</a> werkt met AI; onjuistheden kunnen daarom voorkomen.</>,
        DISCONNECTED_BY_AGENT: 'De chat is beeindigd door de agent.',
        DISCONNECTED_BY_CLIENT: 'De chat is door jou beeindigd',
        CE_CONNECTION_ERROR: 'Door een fout kan de chat niet starten. Open het chatvenster opnieuw of probeer het later nog eens.',
        RECONNECTING_NOTIFICATION: 'Oeps, je internetverbinding is verbroken. Als je weer verbinding hebt, kan je verder chatten.',
        INPUT_PLACEHOLDER: 'Typ hier je bericht.',
        INPUT_PLACEHOLDER_CONNECTING: 'Opnieuw verbinden...',
        MOBILE_QUEUE_WARNING: 'zorg ervoor dat je telefoon niet op stand-by gaat.',
        MODAL_LOGOUT_TITLE: 'Wil je uitloggen?',
        MODAL_LOGOUT_TEXT: 'Als je uitlogt, dan sluit je ook de chat.',
        MODAL_LOGOUT_BUTTON_TEXT: 'Uitloggen',
        MODAL_RESTART_TITLE: 'Wil je deze chat opnieuw starten?',
        MODAL_END_TITLE: 'Wil je deze chat sluiten?',
        MODAL_AUTHENTICATED_TEXT: 'Hiermee log je uit en raak je het gesprek kwijt.',
        MODAL_TEXT: 'Je raakt daarmee het hele gesprek kwijt.',
        MODAL_RESTART_BUTTON_TEXT: 'Start de chat opnieuw',
        MODAL_END_BUTTON_TEXT: 'Chat sluiten',
        ZERO_QUEUE_POSITION: 'Je bent de eerst volgende.',
        ONE_QUEUE_POSITION: 'Er is nog 1 wachtende voor jou.',
        PLURAL_QUEUE_POSITION: 'Er zijn nog {position} wachtenden voor jou.',
        WARNING_IDLE: 'Je hebt al een tijdje niet gereageerd. Laat even weten of je er nog bent, anders sluiten we deze chat over',
        DISCONNECT_IDLE: 'De chat is gestopt omdat we te lang niet van je hebben gehoord.',
        THANK_YOU: 'Bedankt dat je contact hebt opgenomen met onze klantenservice.',
        LIVE_CHAT_INTRO_TEXT: 'Blijf je in de buurt wanneer je wordt geholpen? Wanneer je lange tijd niet reageert, wordt dit gesprek automatisch afgesloten.'
    },

    BM: {
        AGENT_CONNECTING: 'We zijn bezig om je door te verbinden met een medewerker. Sneller aan de beurt? Tussen 08:00 en 10:00, of na 14:00 is het vaak rustig op LiveChat.',
        AGENT_CONNECTED: 'Gelukt!',
        AGENT_CONNECTED_NAME: ' Je praat vanaf hier met ',
        HANDOVER_FAILED: 'Doorverbinden is mislukt. Jouw gesprek wordt wordt voortgezet met de huidige medewerker.',
        DATA_PRIVACY_DISCLAIMER: 'Deze chat wordt bewaard ter verbetering van onze service.',
        DATA_PRIVACY_DISCLAIMER_NEW: <>Deze chat wordt bewaard ter verbetering van onze service.<br /><a href={process.env.REACT_APP_DISCLAIMER_PAGE_URL}>Dot</a> werkt met AI; onjuistheden kunnen daarom voorkomen.</>,
        DISCONNECTED_BY_AGENT: 'De chat is beeindigd door de agent',
        DISCONNECTED_BY_CLIENT: 'De chat is door jou beeindigd',
        CE_CONNECTION_ERROR: 'Door een fout kan de chat niet starten. Open het chatvenster opnieuw of probeer het later nog eens.',
        RECONNECTING_NOTIFICATION: 'Oeps, je internetverbinding is verbroken. Als je weer verbinding hebt, kunt u verder chatten.',
        INPUT_PLACEHOLDER: 'Typ hier je bericht',
        INPUT_PLACEHOLDER_CONNECTING: 'Opnieuw verbinden...',
        MOBILE_QUEUE_WARNING: 'zorg ervoor dat je telefoon niet op stand-by gaat.',
        MODAL_RESTART_TITLE: 'Wil je deze chat opnieuw starten?',
        MODAL_END_TITLE: 'Wil je deze chat sluiten?',
        MODAL_TEXT: 'Je raakt daarmee het hele gesprek kwijt.',
        MODAL_RESTART_BUTTON_TEXT: 'Start de chat opnieuw',
        MODAL_END_BUTTON_TEXT: 'Chat sluiten',
        ZERO_QUEUE_POSITION: 'Je bent de eerst volgende',
        ONE_QUEUE_POSITION: 'Er is nog 1 wachtende voor je',
        PLURAL_QUEUE_POSITION: 'Er zijn nog {position} wachtenden voor je',
        WARNING_IDLE: 'Je hebt al een tijdje niet gereageerd. Laat even weten of je er nog bent, anders sluiten we deze chat over',
        DISCONNECT_IDLE: 'De chat is gestopt omdat we te lang niet van u hebben gehoord.',
        THANK_YOU: 'Bedankt dat je contact hebt opgenomen met onze klantenservice',
        LIVE_CHAT_INTRO_TEXT: 'Blijft je in de buurt wanneer je wordt geholpen? Wanneer je lange tijd niet reageert, wordt dit gesprek automatisch afgesloten.'
    }
};

export const CE_CONNECTION_ERROR_MESSAGE = 'Door een fout kan de chat niet starten. ' +
    'Open het chatvenster opnieuw of probeer het later nog eens.';
export const BOT_DISPLAY_NAME = 'Dot';

// Notification constants
export const NOTIFICATION_SUCCESS = 'NOTIFICATION_SUCCESS';
export const NOTIFICATION_INFO = 'NOTIFICATION_INFO';
export const NOTIFICATION_CAUTION = 'NOTIFICATION_CAUTION';
export const NOTIFICATION_ERROR = 'NOTIFICATION_ERROR';
export const NOTIFICATION_IDLE = 'NOTIFICATION_IDLE';

// Events send to CE
export const CHAT_STOP = 'CHAT_STOP';
export const CHAT_STOP_WITH_RETRY = 'CHAT_STOP_WITH_RETRY';
export const USER_CONNECTED = 'USER_CONNECTED';
export const USER_TYPING = 'USER_TYPING';
export const USER_NOT_TYPING = 'USER_NOT_TYPING';

// WS constants
export const WS_CONNECTION_OPEN = 'WS_CONNECTION_OPEN';
export const WS_CONNECTION_CLOSED = 'WS_CONNECTION_CLOSED';
export const WS_CONNECTION_RECONNECTING = 'WS_CONNECTION_RECONNECTING';
export const WS_CONNECTION_FROZEN = 'WS_CONNECTION_FROZEN';
export const WS_WARNING_RETRY_ATTEMPTS = 3;
export const WS_MAX_RETRY_ATTEMPTS = 12;
export const WS_MESSAGE_RETRY_INTERVAL = 3000;

// PDF constants
export const PDF_TITLE_FONT_SIZE = 20;
export const PDF_TITLE_Y_POS = 35;
export const PDF_X_OFFSET = 20;
export const PDF_Y_OFFSET_MESSAGES = 50;
export const PDF_Y_MARGIN = 25;
export const PDF_MESSAGE_FONT_SIZE = 14;
export const PDF_MAX_LINE_LENGTH = 160;
export const PDF_TRANSCRIPT_FILENAME = "transcript.pdf";

// Mobile app constants
export const APP_ID = "ceChatEngine";

// KPN subdomain red-listed
export const POP_UP_KPN_SUBDOMAIN_LINKS = ['mijn', 'forum', 'zakelijkforum', 'cp.online', 'reparatie'];

// Cookie constants
export const NUANCE_SESSION = 'NUANCE_SESSION';
export const CE_PARAMS = 'CE_PARAMS';
export const CHAT_SESSION = 'CHAT_SESSION';

// Adobe
export const UNSAFE_SELECTABLE_ENTITIES = ['FE_CUSTOMER_PRODUCTS'];

// Animation constants
export * from './animation.js';
